// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: white;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.footer .MuiIconButton-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(255, 255, 255, 0.6); /* İkonların varsayılan rengi */
}

.footer .MuiTypography-caption {
  margin-top: 5px;
}

.footer .MuiIconButton-root.active {
  color: white;
}

.active-icon {
  color: rgba(255, 255, 255, 0.9); /* Aktif ikonların rengi */
}
`, "",{"version":3,"sources":["webpack://./src/style/Footer.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,WAAW;EACX,sBAAsB;EACtB,YAAY;EACZ,eAAe;EACf,yCAAyC;EACzC,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,+BAA+B,EAAE,+BAA+B;AAClE;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,+BAA+B,EAAE,0BAA0B;AAC7D","sourcesContent":[".footer {\r\n  position: fixed;\r\n  bottom: 0;\r\n  width: 100%;\r\n  background-color: #333;\r\n  color: white;\r\n  padding: 10px 0;\r\n  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);\r\n  z-index: 1000;\r\n}\r\n\r\n.footer .MuiIconButton-root {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  color: rgba(255, 255, 255, 0.6); /* İkonların varsayılan rengi */\r\n}\r\n\r\n.footer .MuiTypography-caption {\r\n  margin-top: 5px;\r\n}\r\n\r\n.footer .MuiIconButton-root.active {\r\n  color: white;\r\n}\r\n\r\n.active-icon {\r\n  color: rgba(255, 255, 255, 0.9); /* Aktif ikonların rengi */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
