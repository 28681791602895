// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-screen {
    height: 100vh;
    width: 100vw;
    background-color: #000;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .error-message {
    font-size: 24px;
    margin-bottom: 10px;
    background: linear-gradient(90deg, #ffffff, #000000);
    -webkit-background-clip: text;
    color: transparent;
    animation: gradient-animation 5s ease infinite;
    background-size: 200% 200%;
  }
  
  .error-submessage {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .refresh-button {
    background-color: transparent;
    color: #fff;
    margin-top: 20px;
  }
  
  .refresh-button:hover {
    color: #bbb;
  }
  
  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/style/Error.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,sBAAsB;IACtB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,oDAAoD;IACpD,6BAA6B;IAC7B,kBAAkB;IAClB,8CAA8C;IAC9C,0BAA0B;EAC5B;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,6BAA6B;IAC7B,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE;MACE,2BAA2B;IAC7B;IACA;MACE,6BAA6B;IAC/B;IACA;MACE,2BAA2B;IAC7B;EACF","sourcesContent":[".error-screen {\r\n    height: 100vh;\r\n    width: 100vw;\r\n    background-color: #000;\r\n    color: #fff;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    text-align: center;\r\n  }\r\n  \r\n  .error-message {\r\n    font-size: 24px;\r\n    margin-bottom: 10px;\r\n    background: linear-gradient(90deg, #ffffff, #000000);\r\n    -webkit-background-clip: text;\r\n    color: transparent;\r\n    animation: gradient-animation 5s ease infinite;\r\n    background-size: 200% 200%;\r\n  }\r\n  \r\n  .error-submessage {\r\n    font-size: 18px;\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  .refresh-button {\r\n    background-color: transparent;\r\n    color: #fff;\r\n    margin-top: 20px;\r\n  }\r\n  \r\n  .refresh-button:hover {\r\n    color: #bbb;\r\n  }\r\n  \r\n  @keyframes gradient-animation {\r\n    0% {\r\n      background-position: 0% 50%;\r\n    }\r\n    50% {\r\n      background-position: 100% 50%;\r\n    }\r\n    100% {\r\n      background-position: 0% 50%;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
